<template>
  <div
    class="flex justify-center items-center  bg-white opacity-75 z-10"
    :class="{ 'h-full w-full absolute top-0 left-0': fullscreen }"
  >
    <div class="animate-spin spinner-border" :class="`${color}`"></div>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'text-green-500'
    },
    fullscreen: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.spinner-border {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  vertical-align: -0.125em;
  border: 0.5em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
}
</style>
