import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.scss'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'

import LoadingComponent from '@/components/LoadingComponent.vue'

const requireAll = (requireContext) => requireContext.keys().map(requireContext)
const req = require.context('@/assets/icon', true, /\.svg$/)
requireAll(req)

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VueCookies)
  .component('LoadingComponent', LoadingComponent)
  .mount('#app')
