import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/admin',
    component: () => import('../views/DashboardView.vue'),
    children: [
      {
        path: '/admin/webhooklist',
        component: () => import('../views/WebhookListView.vue')
      },
      {
        path: '/admin/userpermission',
        component: () => import('../views/UserPermissionView.vue')
      },
      {
        path: '/admin/updatelog',
        component: () => import('../views/UpdateLogView.vue')
      },
      {
        path: '/admin/excelaction',
        component: () => import('../views/ExcelActionView.vue')
      },
      {
        path: '/admin/cusprset',
        component: () => import('../views/CusPrSetView.vue')
      },
      {
        path: '/admin/cusprsetexcel',
        component: () => import('../views/CusPrSetExcelActionView.vue')
      },
      {
        path: '/admin/cusprsettransfer',
        component: () => import('../views/CusPrSetTransferView.vue')
      },
      {
        path: '/admin/permissionlist',
        component: () => import('../views/PermissionListView.vue')
      },
      {
        path: '/admin/cusservices',
        component: () => import('../views/CusServicesView.vue')
      },
      {
        path: '/admin/cuscashier',
        component: () => import('../views/CusCashierView.vue')
      },
      {
        path: '/admin/prsetsetting',
        component: () => import('../views/PrSetSettingView.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
